import axios from "axios";
import Cookies from "js-cookie";

function createClient() {
  const token = Cookies.get("token");

  const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    },
  });

  // interceptors are like middleware for axios
  // they will be call everytime axios receive a response
  // more info here: https://github.com/axios/axios#interceptors
  instance.interceptors.response.use(
    (response) => response,
    (err) => {
      console.log("interceptor");

      // we have to handle the case were there is no response at all (e.g.: no internet)
      if (!err.response) throw err;

      // here we handle a common case when the user try to request protected info without a valid token and returns a 401 Unauthorized
      const isUnauthorized = err.response.status === 401;

      const isLoginRequest = err.config.url.includes("/login");
      // if the ctx is present, we can assume the token in the cookie expired
      // so we redirect to /login
      if (isUnauthorized) {
        Cookies.remove("token");
        if (!isLoginRequest) {
          window.location.href = "/login?failed=true&reason=expiredToken";
        }
        return Promise.reject(err);
      }

      return Promise.reject(err);
    }
  );

  return instance;
}
const axiosInstance = createClient();
export default axiosInstance;

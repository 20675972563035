import { compareVersions, validate } from "compare-versions";
import { Button } from "primereact/button";
import { Messages } from "primereact/messages";
import { Skeleton } from "primereact/skeleton";
import { Tag } from "primereact/tag";
import { Tooltip } from "primereact/tooltip";
import { Tree } from "primereact/tree";
import React, { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import axiosInstance from "../../utils/client";
import { addMapToMap, checkExtensionLicenced, downloadExtension } from "../../utils/extensionHelper";
import { LoadingContext } from "../Layout";
import "./ExtensionList.css";

function ExtensionList() {
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const [nodes, setNodes] = useState();
  const [error, setError] = useState(false);
  const errorMessages = useRef(null);
  const [licenses, setLicenses] = useState([]);
  const [attachments, setAttachments] = useState(null);
  const [workitemIds, setWorkItemIds] = useState(null);

  var headNodeTemplates = [
    {
      key: "0",
      label: "Your Extensions",
      icon: "pi pi-fw pi-lock-open",
      children: [],
      className: "node-header",
    },
    {
      key: "1",
      label: "Non-licensed Extensions",
      icon: "pi pi-fw pi-lock",
      children: [],
      className: "node-header",
    },
    {
      key: "2",
      label: "Expired Extensions",
      icon: "pi pi-fw pi-lock",
      children: [],
      className: "node-header",
    },
  ];

  useEffect(() => {
    if (!workitemIds) return;
    const attachmentsAsMap = new Map();
    for (let i = 0; i < workitemIds.length; i++) {
      showLoading();
      axiosInstance
        .get(`extensions/${workitemIds[i]}/attachments`)
        .then((response) => {
          attachmentsAsMap.set(workitemIds[i], response.data.data);
        })
        .catch((error) => {
          console.log(error);
          window.location.href = "/";
        })
        .then(() => hideLoading());
    }
    setAttachments(attachmentsAsMap);
  }, [workitemIds]);

  useEffect(() => {
    var licensesTmp = [];
    showLoading();
    axiosInstance
      .get("license")
      .then((response) => {
        response.data?.data?.forEach((lic) => {
          licensesTmp.push(lic);
        });
        setLicenses(licensesTmp);
      })
      .catch((error) => {
        console.log(error);
        setError(true);
        toast.error(error.response?.data.details);
      })
      .finally(() => hideLoading());
  }, []);

  useEffect(() => {
    if (licenses.length === 0) return;
    showLoading();
    axiosInstance
      .get("extensions/")
      .then((response) => {
        var node = [];
        var workitemIdTmp = [];

        const allExtensions = response.data.data;
        var allExpiredLicenses = new Map();
        allExtensions.forEach((element) => {
          workitemIdTmp.push(element.attributes.id);
          var expiredLicenses = checkExtensionLicenced(licenses, element.attributes.relatedLicense);
          allExpiredLicenses = addMapToMap(allExpiredLicenses, expiredLicenses);
          if (!element.relationships.attachments.data) return;
          const selector = element.attributes.subtype?.[0]
            ? `${element.attributes.type}_${element.attributes.subtype[0]}`
            : `${element.attributes.type}`;
          if (expiredLicenses.has("good") || expiredLicenses.has("warn")) {
            createTypeNode(node, 0, element.attributes.type, element.attributes.subtype?.[0], "available");
            addExtensionNode(node, 0, element, `available_${selector}`, true);
          } else if (expiredLicenses.has("error")) {
            addExtensionNode(node, 2, element, null, false);
          } else {
            createTypeNode(node, 1, element.attributes.type, element.attributes.subtype?.[0], "not_available");
            addExtensionNode(node, 1, element, `not_available_${selector}`, false);
          }
        });
        setMessage(allExpiredLicenses);
        setWorkItemIds(workitemIdTmp);
        setNodes(node);
      })
      .catch((error) => {
        console.log(error);
        setError(true);
        toast.error(error.response?.data.details);
      })
      .finally(() => hideLoading());
  }, [licenses]);

  function setMessage(expiredLicenses) {
    if (errorMessages.current) {
      errorMessages.current.clear();
      expiredLicenses.forEach((value, key) => {
        if (key === "no" || key === "good") return;
        errorMessages.current.show({
          id: key,
          sticky: true,
          severity: key,
          content: value,
          closable: true,
        });
      });
    }
  }

  function addExtensionNode(node, index, element, addToKey, enabled) {
    console.log(addToKey);
    if (node[index] === undefined) {
      node[index] = headNodeTemplates[index];
    }
    if (addToKey) {
      node[index].children
        .filter((item) => item.key === addToKey)[0]
        .children.push({
          key: element.attributes.id,
          label: element.attributes.title,
          data: element,
          enabled: enabled,
          children: [],
        });

      node[index].children
        .filter((item) => item.key === addToKey)[0]
        .children.sort((a, b) => a.data.attributes.title.localeCompare(b.data.attributes.title));
    } else {
      node[index].children.push({
        key: element.attributes.id,
        label: element.attributes.title,
        data: element,
        enabled: enabled,
        children: [],
      });

      node[index].children.sort((a, b) => a.data.attributes.title.localeCompare(b.data.attributes.title));
    }
  }

  function createTypeNode(node, index, type, subtype, seperator) {
    var contains = false;
    var label = type;
    const keyName = subtype ? `${seperator}_${type}_${subtype}` : `${seperator}_${type}`;
    console.log(keyName);
    if (node[index] === undefined) {
      node[index] = headNodeTemplates[index];
    }
    node[index].children.forEach((item) => {
      if (item.key === `${keyName}`) {
        contains = true;
        return;
      }
    });

    switch (type) {
      case "avaTemplate":
        label = "Project Templates";
        break;
      case "avaPlugin":
        switch (subtype) {
          case "java":
            label = "Plugins";
            break;
          case "velocity":
            label = "Widgets";
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }

    if (!contains) {
      node[index].children.push({
        key: `${keyName}`,
        label: label,
        icon: "pi pi-fw pi-folder",
        children: [],
        className: "node-header",
      });
    }
  }

  const nodeTemplate = (node) => {
    if (node.hasOwnProperty("data")) {
      return extensionTemplate(node.data, node.enabled);
    } else {
      return <div className="p-d-flex p-ai-center">{node.label}</div>;
    }
  };

  function getLatestAttachmentInfo(attachments) {
    var version = "N/A";
    var severity = "danger";
    var latestVersion;
    var latestId;
    var attachmentIsPresent = false;

    if (attachments) {
      for (var attachment of attachments) {
        if (attachment.id.endsWith(".zip") || attachment.id.endsWith(".jar")) {
          attachmentIsPresent = true;
          version = attachment.id.match(/(?<=[_| |+|-])\d+\.\d+(\.?\d+)*(?=[_| |+|-|.])/g);
          if (version != null && version.length !== 0 && validate(version[0])) {
            if (!latestVersion || compareVersions(latestVersion, version[0]) < 0) [latestVersion, latestId] = [version[0], attachment.id];
          }
        }
      }

      if (attachmentIsPresent && latestVersion) {
        version = "v" + latestVersion;
        severity = "success";
      } else if (attachmentIsPresent) {
        version = "No Version Number";
        severity = "info";
      }
    }
    return [severity, version, latestId.replace(/^.*\//g, "")];
  }

  function getReleaseTime(attachments, version, fallbackDate) {
    var currentDate = new Date();
    var updatedString;
    var updated = new Date(fallbackDate);
    var isNew = false;

    for (var attachment of attachments) {
      if ((attachment.id.endsWith(".zip") || attachment.id.endsWith(".jar")) && attachment.id.includes(version)) {
        var date = attachment.id.match(/(?<=[_|(])\d{4}\d{2}\d{2}(?=[_|+|-])/g);
        if (date != null && date.length !== 0) {
          var year = date[0].substring(0, 4);
          var month = date[0].substring(4, 6);
          var day = date[0].substring(6, 8);
          updated = new Date(year, month - 1, day);
        }
        break;
      }
    }
    updatedString = updated.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
    isNew = updated.getTime() > currentDate.getTime() - 30 * 24 * 60 * 60 * 1000;
    return [updatedString, isNew];
  }

  const extensionTemplate = (extension, enabled) => {
    var [severity, version, attachmentId] = getLatestAttachmentInfo(extension.relationships.attachments.data);

    var attachment = attachments.get(extension.attributes.id)?.find((entry) => entry.attributes.id === attachmentId);
    if (!attachment) return <Skeleton height="4rem"></Skeleton>;

    var [updated, isNew] = getReleaseTime(extension.relationships.attachments.data, version.replace("v", ""), attachment.attributes.updated);

    const button = (
      <Button
        id={extension.attributes.id}
        icon="pi pi-download"
        className="custom-pi-button"
        disabled={!enabled}
        onClick={() => getExtensionFile(extension.attributes.id)}
      />
    );

    function openExtensionPage(e) {
      var selection = window.getSelection();
      if ((e.target.tagName === "SPAN" || e.target.tagName === "BUTTON") && e.target.className.includes("p-button-icon")) return;
      if (selection.type === "Range" || selection.toString()) return;
      window.location.href = "/extensions/" + extension.attributes.id;
    }

    return (
      <div className="d-flex extension-node" onClick={openExtensionPage}>
        {console.log(extension)}
        {enabled ? <div className="extension-button">{button}</div> : <div title="Contact Avasis Sales">{button}</div>}
        <div className="row column-extension-name">
          <div className="fw-bolder col-md-12 extension-name">{extension.attributes.title}</div>
          <div className="float-left">
            <Tooltip target=".extension-tag" content="Latest version" position="top" showDelay={1000} className="extensionlist-tooltip" />
            <Tag className="extension-tag" value={version} severity={severity}></Tag>
            {severity !== "danger" ? (
              <>
                <Tooltip
                  target=".extension-date-tag"
                  content="Latest release date"
                  position="top"
                  showDelay={1000}
                  className="extensionlist-tooltip"
                />{" "}
                <Tag className="extension-date-tag" value={updated} severity="info"></Tag>{" "}
              </>
            ) : null}
            {isNew && severity !== "danger" ? (
              <>
                <Tooltip
                  target=".extension-new-tag"
                  content="Released within the last 30 days"
                  position="top"
                  showDelay={1000}
                  className="extensionlist-tooltip"
                />{" "}
                <Tag className="extension-new-tag" value="NEW RELEASE" severity="Warning"></Tag>{" "}
              </>
            ) : null}
          </div>
        </div>
        <div className="extension-short-description">{extension.attributes.shortDescription}</div>
      </div>
    );
  };

  async function getExtensionFile(workItemId) {
    showLoading();
    await downloadExtension(workItemId, "latest")
      .then(
        () => {},
        (error) => {
          setError(true);
          toast.error(error);
        }
      )
      .then(() => hideLoading());
  }

  return (
    <>
      <div className="container">
        {!error ? (
          <>
            {nodes && licenses && attachments && workitemIds ? (
              <>
                <Messages ref={errorMessages} className="messages" />
                <Tree
                  dragdropScope=""
                  style={{ border: "none", paddingLeft: "0px" }}
                  expandedKeys={{
                    0: true,
                    available_avaTemplate: true,
                    available_avaPlugin_java: true,
                    available_avaPlugin_velocity: true,
                  }}
                  value={nodes}
                  nodeTemplate={nodeTemplate}
                  className="w-full"
                />{" "}
              </>
            ) : (
              <div>
                <Skeleton height="15rem" className="m-6"></Skeleton>
              </div>
            )}
          </>
        ) : (
          <h2 className="mt-8">There was an error loading Extensions. Please try again later or contact avasis if the problem persists.</h2>
        )}
      </div>
    </>
  );
}

export default ExtensionList;

import { Card } from "primereact/card";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import axiosInstance from "../../utils/client";
import { LoadingContext } from "../Layout";

import ChangePasswordButton from "./ChangePasswordButton";
import "./Profile.css";

const Profile = () => {
  const { loadingCount, showLoading, hideLoading } = useContext(LoadingContext);
  const [error, setError] = useState(false);

  const [customer, setCustomer] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    getData("customer", setCustomer);
    getData("customer/user", setUser);
  }, []);

  function getData(url, setData) {
    showLoading();
    axiosInstance
      .get(url)
      .then((response) => {
        if (response.data) {
          setData(response.data);
          console.log(response.data);
        } else {
          setError(true);
          console.log("No data found!");
        }
      })
      .catch((error) => {
        console.log(error);
        setError(true);
        toast.error(error.response?.data.details);
      })
      .finally(() => hideLoading());
  }

  return (
    <>
      {!error ? (
        <>
          {user && customer ? (
            <div className="container">
              <Card className="profile-card">
                <div className="grid nested-grid">
                  <div className="col-3">
                    <i className="pi pi-user" style={{ fontSize: "10rem", marginTop: "50px" }}></i> <br></br>
                    <p style={{ textAlign: "center", margin: "12px" }}>
                      {user.attributes?.title} <br /> {user.attributes?.username}
                    </p>
                    <ChangePasswordButton />
                  </div>
                  <div className="col-9">
                    <h1>User Information</h1>
                    <div className="grid" style={{ marginTop: "10px" }}>
                      <div className="col-12">
                        <div className="info-row">
                          <h4>License Information</h4>
                          <span>{customer.attributes?.title}</span>
                        </div>
                        {/* <div className="info-row">
                          <h4>Industry</h4>
                          <span>{customer.attributes?.industry}</span>
                        </div>
                        <div className="info-row">
                          <h4>Customer Sites</h4>
                          <span>{customer.attributes?.customerSites}</span>
                        </div>
                        <div className="info-row">
                          <h4>Customer Products</h4>
                          <span>{customer.attributes?.customerProducts}</span>
                        </div>
                        <div className="info-row">
                          <h4>Vertec ID</h4>
                          <span>{customer.attributes?.vertecID}</span>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          ) : null}
        </>
      ) : (
        <h2 className="mt-8">There was an error loading the user. Please try again later or contact avasis if the problem persists.</h2>
      )}
    </>
  );
};
export default Profile;

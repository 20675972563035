import { saveAs } from "file-saver";
import { MDBAccordion, MDBAccordionItem, MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Message } from "primereact/message";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import axiosInstance from "../../utils/client";
import { LoadingContext } from "../Layout";
import "./LicenseTable.css";

function LicenseTable() {
  const { loadingCount, showLoading, hideLoading } = useContext(LoadingContext);
  const [allLicense, setAllLicense] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    showLoading();
    axiosInstance
      .get("license")
      .then((response) => {
        if (response.data.data) {
          setAllLicense(response.data.data);
          console.log(response.data.data);
        } else {
          setError(true);
          setAllLicense([]);
          console.log("No licenses found!");
        }
      })
      .catch((error) => {
        console.log(error);
        setError(true);
        toast.error(error.response?.data.details);
      })
      .finally(() => hideLoading());
  }, []);

  const downloadLicense = (licenseId) => {
    axiosInstance
      .get("license/file/" + licenseId)
      .then((response) => {
        showLoading();
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        saveAs(blob, "avasis.license");
        hideLoading();
      })
      .catch((error) => {
        showLoading();
        setError(true);
        console.error("Error downloading file:", error);
        toast.error(error.response?.data.details);
      });
  };

  const showPlugins = (licence) => {
    var plugins = new Map();
    for (let attribute in licence) {
      if (attribute.startsWith("biz.avasis.polarion.")) {
        var plugin = attribute
          .replace(/^biz\.avasis\.polarion\./, "")
          .split(/(?=[A-Z])/)
          .join(" ")
          .replace(/\b\w/g, (char) => char.toUpperCase());
        plugins.set(plugin, licence[attribute]);
      }
    }
    var licensedPlugins = [];
    var unlicensedPlugins = [];
    plugins.forEach((pluginInstalled, pluginName) => {
      licensedPlugins.push(<Message key={pluginName} severity="success" style={{ margin: "2px" }} text={pluginName} />);
    });
    return (
      <div>
        {licensedPlugins}
        <div>{unlicensedPlugins}</div>
      </div>
    );
  };

  function formatDateString(dateString) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  }

  function formatUserLimit(userLimit) {
    if (userLimit === -1) {
      return "Unlimited";
    } else {
      return userLimit;
    }
  }

  function validateLicense(license) {
    const licenseDate = new Date(license.attributes.validUntil);
    const currentDate = new Date();

    if (licenseDate < currentDate) {
      return <div className="license-not-valid">{license.attributes?.title}</div>;
    } else if (licenseDate < currentDate.setDate(currentDate.getDate() + 90)) {
      return <div className="license-expiring">{license.attributes?.title}</div>;
    } else {
      return license.attributes?.title;
    }
  }

  return (
    <>
      {!error ? (
        <div className="container">
          <Card className="licenseTable-card">
            <div class="grid">
              <div class="col">
                <h1>{allLicense[0]?.attributes?.customerName}</h1>
                <br></br>
                <MDBAccordion initialActive={0}>
                  {allLicense.map((lic, index) => (
                    <MDBAccordionItem collapseId={index} headerTitle={validateLicense(lic)}>
                      <MDBCardBody
                        style={{
                          borderLeft: "solid",
                          borderWidth: "0.1px",
                          borderColor: "#d4d4d4",
                        }}
                      >
                        <div className="container">
                          <div className="row">
                            <div className="col-sm-6 profileTags">License Type</div>
                            <div className="col-sm-6 profileValue">{lic.attributes?.licenseType}</div>
                          </div>
                          <hr></hr>
                          <div className="row">
                            <div className="col-sm-6 profileTags">User Limit</div>
                            <div className="col-sm-6 profileValue">{formatUserLimit(lic.attributes.userLimit)}</div>
                          </div>
                          <hr></hr>
                          <div className="row">
                            <div className="col-sm-6 profileTags">Valid Until</div>
                            <div className="col-sm-6 profileValue">{loadingCount > 0 ? "" : formatDateString(lic.attributes.validUntil)}</div>
                          </div>
                          <hr></hr>
                          <div className="row">
                            <div className="col-sm-6 profileTags">Plugins</div>
                            <div className="col-sm-6 profileValue">{showPlugins(lic.attributes)}</div>
                          </div>
                        </div>

                        <hr style={{ color: "grey" }}></hr>
                        <p>Download Your License</p>
                        <Button
                          label="Click Here"
                          onClick={() => downloadLicense(lic.attributes?.id)}
                          icon="pi pi-download"
                          disabled={loadingCount > 0}
                          className="avasisButton licenseButton"
                        />
                      </MDBCardBody>
                    </MDBAccordionItem>
                  ))}
                </MDBAccordion>
              </div>
            </div>
          </Card>
        </div>
      ) : (
        <h2 className="mt-8">There was an error loading the License. Please try again later or contact avasis if the problem persists.</h2>
      )}
    </>
  );
}

export default LicenseTable;

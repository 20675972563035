import Cookies from "js-cookie";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import ExtensionPage from "./components/ExtensionPage/ExtensionPage";
import Extensions from "./components/Extensions/extensions";
import Home from "./components/Home/Home";
import Layout from "./components/Layout";
import LicenseTable from "./components/LicenseTable/LicenseTable";
import LoginPage from "./components/Login/LoginPage";
import Profile from "./components/Profile/Profile";
import axiosInstance from "./utils/client";
import "./utils/headingHelper";
import {
  extensionsDescription,
  extensionsTitle,
  homeDescription,
  homeTitle,
  licensesDescription,
  licensesTitle,
  profileDescription,
  profileTitle,
} from "./utils/headingHelper";

function App() {
  const api_regex = /^\/api\/.*/;
  if (api_regex.test(window.location.pathname)) {
    return null;
  } else if (Cookies.get("token") === "" || Cookies.get("token") === null || Cookies.get("token") === undefined) {
    return (
      <div className="App">
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <Routes>
          <Route path="/login" element={<LoginPage />}></Route>
          <Route path="*" element={<Navigate to="/login" replace={true} />} />
        </Routes>
      </div>
    );
  } else {
    axiosInstance.get("auth/jwtValid");
    return (
      <div className="App">
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <Routes>
          <Route
            path="/"
            element={
              <Layout headingTitle={homeTitle} headingDescription={homeDescription}>
                <Home />
              </Layout>
            }
          ></Route>
          <Route
            path="/profile"
            element={
              <Layout headingTitle={profileTitle} headingDescription={profileDescription}>
                <Profile />
              </Layout>
            }
          ></Route>
          <Route
            path="/licenses"
            element={
              <Layout headingTitle={licensesTitle} headingDescription={licensesDescription}>
                <LicenseTable />
              </Layout>
            }
          ></Route>
          <Route
            path="/extensions"
            element={
              <Layout headingTitle={extensionsTitle} headingDescription={extensionsDescription}>
                <Extensions />
              </Layout>
            }
          ></Route>
          <Route
            path="/extensions/:workitemId"
            element={
              <Layout headingTitle="avasis Customer Portal" headingDescription="">
                <ExtensionPage />
              </Layout>
            }
          ></Route>
          <Route path="*" element={<Navigate to="/" replace={true} />} />
        </Routes>
      </div>
    );
  }
}

export default App;

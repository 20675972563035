import { saveAs } from "file-saver";
import axiosInstance from "./client";

function getLicenseErrorsAndWarnings(license) {
  var messages = new Map();
  const currentDate = new Date();
  const validUntilDate = new Date(license.attributes.validUntil);
  if (currentDate > validUntilDate) {
    messages.set(
      "error",
      <span>
        At least one of your licences is expired! Go to your <a href="/licenses">Profile</a>, expired licenses are marked red.
      </span>
    );
  } else if (validUntilDate < currentDate.setDate(currentDate.getDate() + 90)) {
    messages.set(
      "warn",
      <span>
        At least one of your licences will expire soon! Go to your <a href="/licenses">Profile</a>, soon to be expired licenses are marked yellow.
      </span>
    );
  } else {
    messages.set("good", "All your licenses are valid.");
  }
  return messages;
}

function checkExtensionLicenced(lics, artifactId) {
  var returnValue = new Map();
  for (const lic of lics) {
    if (lic.attributes && (lic.attributes.hasOwnProperty(artifactId) || lic.attributes.hasOwnProperty("biz.avasis.polarion.all"))) {
      var licenceErrors = getLicenseErrorsAndWarnings(lic);
      if (licenceErrors.has("good") || licenceErrors.has("warn")) {
        return licenceErrors;
      } else {
        returnValue = licenceErrors;
      }
    }
  }
  return returnValue;
}

function addMapToMap(map, mapToAdd) {
  mapToAdd.forEach((value, key) => {
    map.set(key, value);
  });
  return map;
}

const downloadExtension = (workItemId, attachment) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`extensions/${workItemId}/${attachment}/file`, {
        responseType: "blob",
      })
      .then((response) => {
        console.log(response.headers);
        const filename = response.headers["content-disposition"].split("=")[1];
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        saveAs(blob, filename);
        resolve();
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
        reject(error.message);
      });
  });
};
const getAttachmentInfo = (workItemId, attachmentId) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`extensions/${workItemId}/attachments/${attachmentId}`)
      .then((response) => {
        // console.log(response.data.data);
        resolve(response.data.data);
      })
      .catch((error) => {
        console.error("Error retrieving attachment info:", error);
        reject(error.message);
      });
  });
};

const getWorkItemInfo = (workItemId) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`extensions/${workItemId}`)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        console.error("Error retrieving work item info:", error);
        reject(error.message);
      });
  });
};

export { addMapToMap, checkExtensionLicenced, downloadExtension, getAttachmentInfo, getWorkItemInfo };

import Cookies from "js-cookie";
import React from "react";
import { ReactComponent as ProfileLogo } from "../../img/account_circle_50dp_351F65_FILL0_wght400_GRAD0_opsz48.svg";
import { ReactComponent as AvasisLogo } from "../../img/Logo_Avasis_Claim_farbig_RGB.svg";
import { ReactComponent as MenuLogo } from "../../img/menu_50dp_351F65_FILL0_wght400_GRAD0_opsz48.svg";
import Navbar from "../Navbar/Navbar";
import "./Header.css";

function Header(props) {
  const profileItems = [
    {
      label: "Profile",
      items: [
        {
          label: "Profile",
          icon: "pi pi-user",
          url: "/profile",
        },
        {
          label: "Logout",
          icon: "pi pi-sign-out",
          url: "/login?failed=false&reason=logout",
          command: () => {
            Cookies.remove("token");
          },
        },
      ],
    },
  ];

  const navigationItems = [
    {
      label: "Navigate",
      items: [
        {
          label: "Home",
          icon: "pi pi-home",
          url: "/",
        },
        {
          label: "Extensions",
          icon: "pi pi-arrow-down",
          url: "/extensions",
        },
        {
          label: "Licenses",
          icon: "pi pi-book",
          url: "/licenses",
        },
      ],
    },
  ];

  return (
    <header className="header-container">
      <div className="table-container">
        <table className="responsive-table">
          <tbody>
            <tr className="header-first-row">
              <th>
                <a href="/">
                  <AvasisLogo className="avasisLogo" />
                </a>
              </th>
              <th></th>
              <th>
                <Navbar items={navigationItems} icon={MenuLogo} />
                <Navbar items={profileItems} icon={ProfileLogo} />
              </th>
            </tr>
          </tbody>
        </table>
        <div className=" container text-left mt-4 mb-4 header-second-row">
          <h1>{props.title}</h1>
          <span>{props.description}</span>
        </div>
      </div>
    </header>
  );
}

export default Header;

const homeTitle = "Welcome to the avasis Customer Portal!";
const homeDescription =
  "The avasis customer portal is the main hub to keep you up to date and informed on your avasis Polarion Medical Device Solutions.";

const extensionsTitle = "Your Extensions";
const extensionsDescription = "";

const licensesTitle = "Your Licenses";
const licensesDescription = "";

const profileTitle = "Your Profile";
const profileDescription = "";

export { extensionsDescription, extensionsTitle, homeDescription, homeTitle, licensesDescription, licensesTitle, profileDescription, profileTitle };

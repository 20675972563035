import { MDBCol, MDBContainer, MDBFooter, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <MDBFooter style={{ backgroundColor: "#e7e8ed" }} className="text-center text-lg-start text-muted footerClass">
      <section className="footerSection">
        <MDBContainer className="text-center text-md-start">
          <MDBRow className="mt-3 footer-first-row">
            <MDBCol md="4" lg="3" xl="3" className="mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
              <div>
                avasis solutions GmbH
                <br />
                +49 40 6963 5770
                <br />
                <a href="mailto:polarion@avasis.biz" className="text-reset">
                  polarion@avasis.biz
                </a>
              </div>
              <div className="mt-3 mb-3">
                <a href="https://www.linkedin.com/company/avasis/" className="me-2 text-reset" target="_blank" rel="noreferrer">
                  <MDBIcon color="secondary" fab icon="linkedin" size="lg" />
                </a>
                <a href="https://www.avasis.biz/" className="me-2 text-reset" rel="noreferrer" target="_blank">
                  <MDBIcon color="secondary" fas icon="globe" size="lg" />
                </a>
              </div>
            </MDBCol>
            <MDBCol md="3" lg="4" xl="3" className="mr-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Links</h6>
              <a className="text-reset" href="https://www.avasis.biz/en/polarion-medical-device-solution">
                Solutions
              </a>
              <br />
              <a className="text-reset" href="https://www.avasis.biz/en/on-demand-webinars">
                On-Demand Webinars
              </a>
              <br />
              <a className="text-reset" href="https://www.avasis.biz/en/events-en/event-calender">
                Events
              </a>
            </MDBCol>
            <MDBCol md="3" lg="4" xl="3" className="mb-3 copyright">
              © 2024 Copyright - &nbsp;
              <a className="text-reset fw-bold" href="https://www.avasis.biz/">
                avasis.biz
              </a>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    </MDBFooter>
  );
}
export default Footer;

import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom/dist";
import "./Home.css";

const Home = () => {
  return (
    <div className="container text-left welcomeDiv">
      <span>
        On this portal you will find:
        <ul className="home-list">
          <li>Downloadable software updates and release information for our Polarion Medical Device Solutions</li>
          <li>An overview of your licenses and subscriptions, along with license status</li>
        </ul>
      </span>
      <div className="mb-1">
        <Link to="/extensions">
          <Button className="avasisButton extensionButton" type="button" label="Your Extensions" />
        </Link>
        <Link to="/licenses">
          <Button className="avasisButton extensionButton" type="button" label="Your Licenses" />
        </Link>
      </div>
      <span>
        Can‘t find what you are looking for? Or you have comments on the Customer Portal?
        <br />
        Get in touch with us!
      </span>
    </div>
  );
};

export default Home;

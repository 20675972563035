import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import React, { useRef } from "react";
import { ReactComponent as MenuLogo } from "../../img/menu_50dp_351F65_FILL0_wght400_GRAD0_opsz48.svg";
import "./navbar.css";

export default function PopupDoc(props) {
  const NavigationMenu = useRef(null);

  return (
    <span>
      <Menu model={props.items} popup ref={NavigationMenu} id="popup_menu" />
      <Button className="navButton" onClick={(event) => NavigationMenu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup>
        <props.icon className="menuLogo" />
      </Button>
    </span>
  );
}

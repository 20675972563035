import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { Skeleton } from "primereact/skeleton";
import { TreeTable } from "primereact/treetable";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import axiosInstance from "../../../utils/client";
import { checkExtensionLicenced, downloadExtension } from "../../../utils/extensionHelper";
import { LoadingContext } from "../../Layout";
import "./ExtensionReleases.css";

function ExtensionReleases({ workitemData, licenses }) {
  const [nodes, setNodes] = useState(null);
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const [attachments, setAttachments] = useState(null);
  var isValidLicense = false;

  useEffect(() => {
    if (workitemData) {
      showLoading();
      axiosInstance
        .get(`extensions/${workitemData.attributes.id}/attachments`)
        .then((response) => {
          console.log(response.data.data);
          setAttachments(response.data.data);
        })
        .catch((error) => {
          console.log(error);
          window.location.href = "/";
        })
        .then(() => hideLoading());
    }
  }, [workitemData]);

  const headNodeTemplates = [
    {
      key: "0",
      data: {
        filename: "Releases",
      },
      children: [],
    },
    {
      key: "1",
      data: {
        filename: "Dokumentation",
      },
      children: [],
    },
  ];

  const downloadTemplate = (node, column) => {
    return node.key !== "0" && node.key !== "1" ? (
      <div className="extension-button">
        <Button
          id={node.key}
          icon="pi pi-download"
          className="custom-pi-button"
          disabled={!isValidLicense}
          onClick={() => getExtensionFile(node.workItemId, node.key)}
        />
      </div>
    ) : null;
  };

  async function getExtensionFile(workItemId, attachment) {
    await downloadExtension(workItemId, attachment).then(
      () => {},
      (error) => {
        toast.error(error);
      }
    );
  }

  function buildNodes() {
    let nodes = [];
    workitemData.relationships.attachments.data.forEach((element) => {
      var fileExtension = element.id.match(/(?<=\.)\w+$/g);
      var nodeToAdd = fileExtension.includes("zip") || fileExtension.includes("jar") ? 0 : 1;

      var attachment = attachments.find((att) => att.id === element.id);

      addExtensionNode(nodes, nodeToAdd, attachment, workitemData);
    });
    setNodes(nodes);
  }

  function addExtensionNode(node, index, attachment, workitem) {
    if (node[index] === undefined) {
      node[index] = headNodeTemplates[index];
    }

    var version = attachment.id.match(/(?<=[_| |+|-])\d+\.\d+(\.?\d+)*(?=[_| |+|-|.])/g);
    if (version != null && version.length !== 0) {
      version = "v" + version[0];
    } else {
      version = "";
    }

    var filename = workitem.attributes.title;
    var key = attachment.id.replace(/^.*\//g, "");
    if (index !== 0) {
      filename = attachment.attributes.fileName;
    }

    node[index].children.push({
      key: key,
      data: {
        filename: filename,
        version: version,
        date: getReleaseTime(attachment, version.replace("v", "")),
        size: (attachment.attributes.length / 1000000).toFixed(2) + " MB",
        download: "Download",
      },
      workItemId: workitem.attributes.id,
      children: [],
    });
  }

  function getReleaseTime(attachment, version) {
    var updatedString;
    var updated = new Date(attachment.attributes.updated);

    if ((attachment.id.endsWith(".zip") || attachment.id.endsWith(".jar")) && attachment.id.includes(version)) {
      var date = attachment.id.match(/(?<=[_|(])\d{4}\d{2}\d{2}-\d{4}(?=[_|+|)|-])/g);
      if (date != null && date.length !== 0) {
        var year = date[0].substring(0, 4);
        var month = date[0].substring(4, 6);
        var day = date[0].substring(6, 8);

        var timestamp = date[0].split("-");
        var hour = timestamp[1].substring(0, 2);
        var minute = timestamp[1].substring(2, 4);
        updated = new Date(year, month - 1, day, hour, minute);
      }
    }

    updatedString = updated.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });
    return updatedString;
  }

  function loadSkeleton() {
    return (
      <div>
        <Skeleton height="4rem" className="m-2"></Skeleton>
      </div>
    );
  }
  useEffect(() => {
    if (workitemData && attachments) buildNodes();
  }, [workitemData, attachments]);

  function loadPage() {
    var isValid = checkExtensionLicenced(licenses, workitemData?.attributes.relatedLicense);
    isValidLicense = isValid.has("good") || isValid.has("warn");

    const rowClassName = (node) => {
      return {
        "border-bottom": node.key !== "0" && node.key !== "1" ? "1px solid #d9d9d9" : null,
      };
    };

    return (
      <div className="m-2 text-left file-table">
        <TreeTable
          value={nodes}
          tableStyle={{ minWidth: "50rem" }}
          sortField="version"
          sortOrder="-1"
          rowClassName={rowClassName}
          expandedKeys={{
            0: true,
          }}
        >
          <Column field="filename" header="Filename" expander sortable></Column>
          <Column field="version" header="Version" style={{ width: "10rem" }} sortable></Column>
          <Column field="date" header="Release date" style={{ width: "10rem" }} sortable></Column>
          <Column field="size" header="Size" style={{ width: "10rem" }}></Column>
          <Column header="Download" style={{ width: "10rem" }} body={downloadTemplate}></Column>
        </TreeTable>
      </div>
    );
  }

  return !(workitemData && licenses && attachments) ? loadSkeleton() : loadPage();
}
export default ExtensionReleases;

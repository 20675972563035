import { ProgressBar } from "primereact/progressbar";
import React, { createContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../utils/extensionHelper";
import { getWorkItemInfo } from "../utils/extensionHelper";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";

export const LoadingContext = createContext({
  loadingCount: 0,
  showLoading: () => {},
  closeLoading: () => {},
});

const Layout = ({ children, headingTitle, headingDescription }) => {
  const { workitemId } = useParams();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (workitemId) {
      getWorkItemInfo(workitemId).then((response) => {
        setTitle(response.attributes?.title);
        setDescription(response.attributes?.shortDescription);
      });
    } else {
      setTitle(headingTitle);
      setDescription(headingDescription);
    }
  }, [workitemId]);

  const showLoading = () => {
    toggleLoading((prevState) => {
      return {
        ...prevState,
        loadingCount: prevState.loadingCount + 1,
      };
    });
  };

  const hideLoading = () => {
    toggleLoading((prevState) => {
      return {
        ...prevState,
        loadingCount: prevState.loadingCount > 0 ? prevState.loadingCount - 1 : 0,
      };
    });
  };

  const loadingState = {
    loadingCount: 0,
    showLoading,
    hideLoading,
  };
  const [loading, toggleLoading] = useState(loadingState);

  return (
    <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <Header title={title} description={description} />
      <ProgressBar
        mode="indeterminate"
        style={{
          height: "6px",
          visibility: loading.loadingCount === 0 ? "hidden" : "",
        }}
      ></ProgressBar>
      <main>
        <LoadingContext.Provider value={loading}>{children}</LoadingContext.Provider>
      </main>
      <Footer />
    </div>
  );
};

export default Layout;

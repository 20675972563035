import Cookies from "js-cookie";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import avasisLogo from "../../img/avasis_logo.svg";
import axiosInstance from "../../utils/client";

function LoginPage() {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(false)
    const [queryParameters] = useSearchParams();

    useEffect(() => {
        if (queryParameters.get("failed") === "true") {
            if (queryParameters.get("reason") === "expiredToken") {
                toast.warn("Your session expired. Please login again");
            }
        } else if (queryParameters.get("failed") === "false") {
            console.log(queryParameters.get("reason"));
            if (queryParameters.get("reason") === "logout") {
                toast.info("Successfully logged out");
            }
        }
    }, [queryParameters]);

    const handleLoginClick = (event) => {
        event.preventDefault()
        setLoading(true)
        axiosInstance
            .post(
                "/auth/login",
                {
                    username: username,
                    password: password,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((response) => {
                // Handle authentication success or failure
                console.log("RESPONSE");
                setLoading(false)
                const data = response.data;
                Cookies.set("token", data.token);
                window.location.reload();
            })
            .catch((error) => {
                // Handle network or other errors
                if(error.response){
                console.error(error);
                toast.error(error.response?.data.details);
                }else{
                    toast.error("There was an error contacting the server. Please try again later or contact avasis if the problem persists.")
                }
                setLoading(false)
                setUsername("");
                setPassword("");
            });
    };


    return (
        <div className="flex align-items-center justify-content-center h-screen bg-gray-300" >
            <div className="surface-card p-4 shadow-4 border-round w-8 lg:w-4 h-8">
                <div className="text-center mb-5">
                    <img src={avasisLogo} alt="avasis" style={{ height: "5vw" }} className="mb-3" />
                    <div className="text-900 text-3xl font-medium mb-3">Welcome Back</div>
                    <span className="text-600 font-medium line-height-3">Don't have an account? <a className="font-medium no-underline ml-2 text-blue-500 cursor-pointer" href="mailto:polarion@avasis.solutions">Contact avasis!</a></span>
                </div>

                <form onSubmit={handleLoginClick}>
                    <label htmlFor="username" className="block text-900 font-medium mb-2">Username</label>
                    <InputText id="username" type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} required className="w-full mb-3" />

                    <label htmlFor="password" className="block text-900 font-medium mb-2">Password</label>
                    <InputText id="password" type="password" placeholder="Password" value={password} required onChange={(e) => setPassword(e.target.value)} className="w-full mb-3" />

                    <div className="flex align-items-center justify-content-start mb-6">
                        <a className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer" href="mailto:polarion@avasis.solutions">Forgot your password?</a>
                    </div>

                    <Button disabled={loading} label="Sign In" icon="pi pi-user" className="w-full" />
                </form>
            </div>
        </div >

    )
}

export default LoginPage
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import PasswordChecklist from "react-password-checklist";
import { toast } from "react-toastify";
import axiosInstance from "../../utils/client";

export default function ChangePasswordButton() {
  const [visible, setVisible] = useState(false);

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPasswordIsValid, setNewPasswordIsValid] = useState(true);

  const labelStyle = "profile-dialog-labels font-semibold";
  const inputTextStyle = "profile-dialog-input border-none p-3";
  const buttonStyle = "p-3 w-full border-1";

  const closeDialog = () => {
    setVisible(false);
    setTimeout(() => {
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
    }, 400);
  };

  const changePassword = () => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .patch(
          `auth/changepassword`,
          {
            currentPassword: currentPassword,
            newPassword: newPassword,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          console.error("Error changing password:", error);
          if (error.response?.data?.details) reject(error.response?.data?.details);
          else {
            reject("There was an error changing your password. Please try again later.");
          }
        });
    });
  };

  const handleChangePassword = () => {
    if (newPasswordIsValid) {
      changePassword()
        .then(() => {
          closeDialog();
          toast.info("Your password has been successfully changed.");
        })
        .catch((e) => {
          toast.error(e);
        });
    }
  };
  return (
    <>
      <Button onClick={() => setVisible(true)} className="avasisButton forgotPasswordButton" label="Change Password" />
      <Dialog
        visible={visible}
        dismissableMask={true}
        className="profile-dialog"
        onHide={() => {
          if (!visible) return;
          closeDialog();
        }}
        showHeader={false}
        contentClassName="profile-dialog-content"
      >
        <div className="flex flex-column px-8 py-5 gap-3">
          <div className={`${labelStyle} text-center text-5xl`}>Change Password</div>
          <div className="inline-flex flex-column gap-2">
            <label htmlFor="currentPassword" className={labelStyle}>
              Current Password
            </label>
            <InputText
              id="currentPassword"
              label="Current Password"
              className={inputTextStyle}
              type="password"
              onChange={(e) => setCurrentPassword(e.target.value)}
            ></InputText>
          </div>
          <div className="inline-flex flex-column gap-2">
            <label htmlFor="newPassword" className={labelStyle}>
              New Password
            </label>
            <InputText
              id="newPassword"
              label="New Password"
              className={inputTextStyle}
              type="password"
              onChange={(e) => setNewPassword(e.target.value)}
            ></InputText>
          </div>
          <div className="inline-flex flex-column gap-2">
            <label htmlFor="confirmNewPassword" className={labelStyle}>
              Confirm New Password
            </label>
            <InputText
              id="confirmNewPassword"
              label="Confirm New Password"
              className={inputTextStyle}
              type="password"
              onChange={(e) => setConfirmPassword(e.target.value)}
            ></InputText>
          </div>
          <div>
            <PasswordChecklist
              className="profile-dialog-passwordChecklist"
              rules={["minLength", "specialChar", "number", "capital", "lowercase", "match"]}
              minLength={8}
              value={newPassword}
              valueAgain={confirmPassword}
              onChange={(isValid) => {
                setNewPasswordIsValid(isValid);
              }}
            />
          </div>
          <div className="flex align-items-center gap-2">
            <Button
              label="Change Password"
              onClick={() => handleChangePassword()}
              text
              disabled={!newPasswordIsValid || currentPassword.length === 0}
              className={`${buttonStyle} avasisButton`}
            ></Button>
            <Button label="Cancel" onClick={(e) => closeDialog()} text className={`${buttonStyle} avasisButton`}></Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

import parse from "html-react-parser";
import { Panel } from "primereact/panel";
import { Ripple } from "primereact/ripple";
import { Skeleton } from "primereact/skeleton";
import { Tooltip } from "primereact/tooltip";
import "./ExtensionInfo.css";

function ExtensionInfo({ workitemData }) {
  function createRandomString(length) {
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  }

  const headerTemplate = (options) => {
    const tooltipClassName = createRandomString(10);
    const toggleIcon = options.collapsed ? "pi pi-chevron-down" : "pi pi-chevron-up";
    const className = options.props.tooltip ? `${options.className} info-panel-header ${tooltipClassName}` : `${options.className} info-panel-header`;
    const titleClassName = `${options.titleClassName} pl-1`;
    const togglerClassName = `${options.togglerClassName} info-panel-toggler`;

    console.log(options.props.tooltip);

    return (
      <div>
        <Tooltip
          target={"." + tooltipClassName}
          content={options.props.tooltip}
          position="mouse"
          showDelay={1000}
          className={"extensioninfo-tooltip"}
        />
        <div className={className} onClick={options.onTogglerClick}>
          <span className={titleClassName}>{options.titleElement}</span>
          <button className={togglerClassName}>
            <span className={toggleIcon}></span>
            <Ripple />
          </button>
        </div>
      </div>
    );
  };

  function loadPanel(title, content, collapsed, tooltip) {
    if (!content) return null;

    return (
      <div className="m-2 text-left info-panel">
        <Panel headerTemplate={headerTemplate} header={title} toggleable collapsed={collapsed} tooltip={tooltip}>
          {parse(content)}
        </Panel>
      </div>
    );
  }

  function loadSkeleton() {
    return (
      <div className="m-3">
        <h1>
          <Skeleton width="15rem" height="2rem" className="skeleton-heading"></Skeleton>
        </h1>
        <div className="block">
          <Skeleton height="4rem" className="m-2"></Skeleton>
          <Skeleton height="4rem" className="m-2"></Skeleton>
          <Skeleton height="4rem" className="m-2"></Skeleton>
          <Skeleton height="4rem" className="m-2"></Skeleton>
        </div>
      </div>
    );
  }

  function loadPage() {
    var description = workitemData.attributes.description ? workitemData.attributes.description.value : "";
    var releaseNotes = workitemData.attributes.releaseNotesReleased ? workitemData.attributes.releaseNotesReleased.value : "";
    var changes = workitemData.attributes.changesReleased ? workitemData.attributes.changesReleased.value : "";
    var changeHistory = workitemData.attributes.changeHistory ? workitemData.attributes.changeHistory.value : "";

    const releaseNotesTooltip =
      "Includes information on new features, improvements, fixes, and other information pertinent for primarily end-users, admins, and non-technical stakeholders. ";
    const technicalChangesTooltip =
      "Technical information containing specific modifications made to the product code, for example code changes, configuration changes, API updates, etc., that are relevant for technical stakeholders. ";

    return (
      <div>
        <div className="block" style={{ marginTop: "50px" }}>
          {loadPanel("Description", description, true)}
          {loadPanel("Release Notes (latest version)", releaseNotes, true, releaseNotesTooltip)}
          {loadPanel("Technical Changes (latest version)", changes, true, technicalChangesTooltip)}
          {loadPanel("Change History", changeHistory, true)}
        </div>
      </div>
    );
  }

  return !workitemData ? loadSkeleton() : loadPage();
}
export default ExtensionInfo;
